import React from 'react';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';

const Title = styled.h1`
  font-size: 40px;
`;

const Image = styled.img`
  max-height: 400px;
  display: block;
`;

const Copyright = styled.span`
  text-align: right;
  display: block;
  font-size: 13px;
  color: white;
  margin: 12px 0 32px;
`;

const Wrapper = styled.div`
  max-width: 720px;
  padding: 0 22px;
`;

const Subtitle = styled.h2`
  display: block;
  color: white;
  font-size: 30px;
  margin-top: 32px;
`;

const P = styled.p`
  color: white;
  font-size: 18px;
`;

const UL = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const LI = styled.li`
  color: white;
  margin: 52px 0;

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 20px;
  }
`;

function GinFestival() {
  return (
    <>
      <SEO
        title="Gin & Tonic festival i Aarhus d. 11 og 12 november 2022"
        description=""
      />
      <Layout>
        <Wrapper className="event">
          <Title className="title">
            Kom til Gin & Tonic festival i Aarhus d. 11 og 12 november
          </Title>
          <Image
            className="event__image"
            src="https://www.billetlugen.dk/obj/media/DK-eventim/teaser/222x222/2019/gin&tonic222x222.jpg"
            alt="Gin og Tonic festival"
          />
          <Copyright>Billede fra Billetlugen</Copyright>
          <Subtitle>
            Festivalen til dig, der vil prøve forskellige G&T's eller bare
            opleve en god stemning
          </Subtitle>
          <P className="event__info_explore">
            En Gin & Tonic er ikke bare en Gin og Tonic. Hvert år bliver man
            overrasket over, hvor mange forskellige smage og muligheder en G&T
            kan have.
            <br />
            <br />
            Alt lige fra den enkelt med 3 ingredienser til den mere komplekse,
            med op til 10-12 ingredienser. Der er rigeligt med oplevelser. Af
            samme årsag er det muligt at lave en helt festival, baseret på denne
            skønne drik. Og stemningen tager man heller aldrig fejl af.
            <br />
            <br />
            Selv hvis Gin & Tonic ikke siger dig det store, er det værd at tage
            til. For igen, der er rigtig, rigtig mange smage at opleve. Så mon
            ikke der er en G&T, der rent faktisk kan tiltale dig.
          </P>
          <a
            style={{ maxWidth: 220 }}
            className="btn event__link info__buyticket-link onsale"
            href="https://www.billetlugen.dk/event/gin-tonic-festival-aarhus-2022-ridehuset-14507636/?affiliate=MR4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Køb billet</span>
          </a>
        </Wrapper>
      </Layout>
    </>
  );
}

export default GinFestival;
